export default {
  area: "乡村振兴",
  objectName: "乡村振兴",
  //url
  hostUrl: 'https://xczxapi.zhsq.cloud/',
  apiHttpsUrl: 'https://xczx.zhsq.cloud/',
  apiRequestUrl: 'https://xczxapi.zhsq.cloud/api',
  apiUploadUrl: 'https://xczxapi.zhsq.cloud/api/UploadFile/UploadImgs',
  apiUploadFiles: 'https://xczxapi.zhsq.cloud/api/UploadFile/UploadFiles',
  apiUploadvideo: 'https://xczxapi.zhsq.cloud/api/UploadFile/UploadQiniuVideo',
}